
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// @ts-nocheck
import { Component, Vue, Watch } from 'vue-property-decorator'
import SellerHeader from '@/components/Seller/SellerHeader.vue'
import {
  getTicketsBySeller,
  getCategoriesBySeller,
  getProductsMinimalByEvent,
  getTicketsExcel,
  getSellersOfEvent,
  reassignSeller,
} from '@/services/seller.service'
import { isoDateToDay, isoDateToMonth } from '@/helpers/date'
import { Ticket } from '@/interfaces/ticket.interface'
import { ErrorMessages } from '@/helpers/constants'
import { getNameRoute } from '@/utils/router/router.utils'
import TicketTemplate from '@/components/Seller/TicketTemplate.vue'
import TicketSkeletonTemplate from '@/components/Seller/TicketSkeletonTemplate.vue'

@Component({
  components: {
    SellerHeader,
    TicketTemplate,
    TicketSkeletonTemplate,
  },
})
export default class Tickets extends Vue {
  eventId!: number = localStorage.getItem('selectedEvent')
  public ticketsEcommerce: Ticket[] = []
  public ticketsLink: Ticket[] = []
  public productsAutocomplete: any = []
  public categories: any = []
  public filter = ''
  public loading = false
  public filterByID = ''
  public filterByCategory = ''
  public search = ''
  public full_quota = false
  public skeletonCount = [0, 1, 2, 3, 4]
  public title = ''
  public selectedTicket: Ticket = {}
  public reassignLoading: boolean = false
  public reassignedSeller = ''
  public full_quota_options = [
    { value: 'complete', text: 'Cupo completo' },
    { value: 'incomplete', text: 'Cupo incompleto' },
    { value: false, text: 'Todos' },
  ]
  public sellers_options: any = []
  public sellers: any = []
  public seller_selected = ''
  public timeout = null

  public loadingEcommerce = false
  public loadingLinks = false

  public ecommercePage = 1
  public ecommerceTotalPages = 1
  public perPage = 50
  public linksPage = 1
  public linksTotalPages = 1

  public totalTicketsEcommerce = 0;
  public totalTicketsLinks = 0

  // Params
  public allTickets = this.$route.params.allTickets ? true : false
  public productId = this.$route.query.productId

  async getTickets(getEcommerceTickets = true, getLinkTickets = true) {
    try {
      this.allTickets = getNameRoute(this.$route) === 'all-links'

      const filters = {
        reference: this.search,
        category: this.filterByCategory,
        product: this.filterByID,
        full_quota: this.full_quota,
        seller: this.seller_selected,
      }

      if (getEcommerceTickets) {
        this.loadingEcommerce = true
        const { data, meta } = await getTicketsBySeller(
          this.eventId,
          this.ecommercePage,
          this.perPage,
          filters,
          this.allTickets,
          true
        )

        this.ticketsEcommerce = data
        this.ecommerceTotalPages = Math.ceil(meta.total / 50)
        this.totalTicketsEcommerce = meta.total
        this.loadingEcommerce = false
      }

      if (getLinkTickets) {
        this.loadingLinks = true
        const { data, meta } = await getTicketsBySeller(
          this.eventId,
          this.linksPage,
          this.perPage,
          filters,
          this.allTickets,
          false
        )

        this.ticketsLink = data
        this.linksTotalPages = Math.ceil(meta.total / 50)
        this.totalTicketsLinks = meta.total
        this.loadingLinks = false
      }
    } catch (error) {
      Vue.$toast.error(ErrorMessages.COMMON)
      console.error(error)
    }
  }

  async getCategories() {
    try {
      const data = await getCategoriesBySeller(this.eventId)
      this.categories = data.data.map((category: any) => {
        return {
          value: category.id,
          text: category.name,
        }
      })

      this.categories.unshift({ value: '', text: 'Todos' })

      localStorage.setItem(
        'ticket-qr-categories-filter',
        JSON.stringify(this.categories)
      )
    } catch (error) {
      Vue.$toast.error(ErrorMessages.COMMON)
    }
  }

  async getProducts() {
    try {
      const data = await getProductsMinimalByEvent(this.eventId)
      this.productsAutocomplete = data.data
      this.productsAutocomplete.unshift({ id: '', name: 'Todos' })
    } catch (error) {
      Vue.$toast.error(ErrorMessages.COMMON)
    }
  }

  dateToMonth(isoDate: string, type: string) {
    if (type === 'day') {
      return isoDateToDay(isoDate)
    }

    return isoDateToMonth(isoDate, true)
  }

  searchLink() {
    this.loading = true
    this.search = this.filter

    if (this.search.length == 0 || this.search.length > 3) {
      this.ecommercePage = 1
      this.linksPage = 1

      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.getTickets()
      }, 800)
    }
    this.loading = false
  }

  async selectTicketAndOpenModal(ticket, modal) {
    this.selectedTicket = ticket

    setTimeout(() => {
      this.$bvModal.show(modal)
    })
  }

  async getTicketsInExcel() {
    const loader = Vue.$loading.show()

    const response = await getTicketsExcel(this.eventId)

    loader.hide()

    return response
  }

  async getSellersOfEvent() {
    try {
      this.sellers = await getSellersOfEvent(this.eventId)

      this.sellers_options = [{ id: '', fullname: 'Todos' }, ...this.sellers]
    } catch (error) {
      Vue.$toast.error(ErrorMessages.COMMON)
    }
  }

  async onReassignSeller(sellerId: number) {
    try {
      this.reassignLoading = true
      const response = await reassignSeller(
        this.selectedTicket.ticket_uuid,
        sellerId
      )
      Vue.$toast.success(response.data.message)
      this.$refs['seller-modal'].hide()
      this.getTickets()
    } catch (e) {
      Vue.$toast.error(e.response.data.message)
    } finally {
      this.reassignLoading = false
    }
  }

  /**
   * This method is called after the component is mounted.
   * It retrieves data from local storage, sets the state of the component based on the route name,
   * and makes several API calls to fetch necessary data.
   */
  mounted() {
    // Check if 'ticket-qr-categories-filter' exists in local storage
    const data = localStorage.getItem('ticket-qr-categories-filter')

    // If data exists, parse it to JSON and assign it to categories
    if (data) {
      this.categories = JSON.parse(data)
    }

    // Fetch categories and products
    this.getCategories()
    this.getProducts()

    // If allTickets is true, fetch sellers of the event
    if (this.allTickets) {
      this.getSellersOfEvent()
    }

    // If productId exists, set filterByID to productId
    if (this.productId) {
      this.filterByID = this.productId
    }

    // Set the title based on the current route
    this.title =
      getNameRoute(this.$route) === 'all-links'
        ? 'Todos los Tickets'
        : 'Mis Tickets generados'
  }

  @Watch('ecommercePage', { immediate: true, deep: true })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onEcommercePageChange(nextPage: number, _currentPage: unknown) {
    if (this.productId) this.filterByID = this.productId
    this.ecommercePage = nextPage
    this.getTickets(true, false)
  }

  @Watch('linksPage', { immediate: true, deep: true })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onLinksPageChange(nextPage: number, _currentPage: unknown) {
    if (this.productId) this.filterByID = this.productId
    this.linksPage = nextPage
    this.getTickets(false, true)
  }
}
