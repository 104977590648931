
import { Component, Prop, Vue } from 'vue-property-decorator'
import { getNameRoute } from '@/utils/router/router.utils'

@Component({
  components: {},
})
export default class TicketTemplate extends Vue {
  @Prop() readonly ticket
  @Prop() readonly allTickets
  @Prop() selectTicketAndOpenModal

  goToTicketDetail(uuid: string) {
    this.$router.push({
      name:
        getNameRoute(this.$route) === 'all-links' ? 'Link-how-admin' : 'Link',
      params: { linkId: uuid },
    })
  }

  /*
  async goToRefundTicket(ticket) {
    this.$router.push({
      name: 'Reembolsar Ticket',
      params: {
        ticket_uuid: ticket.uuid,
        event_id: this.eventId,
      },
    })
  }
  */

  async copyToClipBoard(text: string) {
    navigator.clipboard.writeText(text).then(function() {
      Vue.$toast.success('Número de operación copiado con éxito')
    })
  }

  setTicketsQuotaColor(constrain: boolean) {
    if (constrain) {
      return 'text-color-red'
    }
    return 'text-color-blue'
  }

  copyLink() {
    const link = `${process.env.VUE_APP_DOMAIN_ECOMMERCE + '/ticket-verification'}/${this.ticket.uuid}`
    navigator.clipboard.writeText(link).then(function() {
      Vue.$toast.success('Link copiado con éxito')
    })
  }
}
